.data-adapters-table-item {
  display: flex;
  flex-direction: row;
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid whitesmoke;
}

.data-adapters-table-item:hover {
  cursor: pointer;
  background-color: whitesmoke;
}

