@import "./variables.less";
@import "./mixins.less";

html {
  height: 100%;
  overflow: hidden;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-family: "Fira Sans", Arial, sans-serif;
}

.app-wrapper {
  width: 100%;
  height: 100%;

  .navbar-l3 {
    .tab {
      white-space: nowrap;
    }
  }

  a {
    color: @color-blue-dark;
    font-size: 14px;
  }

  div {
    box-sizing: border-box;
  }
}

.container {
  .container();
}

@-webkit-keyframes autofill {
  to {
    // color: #f00;
    background: transparent;
  }
}

input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

.io-tooltip-root {
  z-index: 15000 !important;
}

.io-tooltip {
  font-size: 14px !important;
  text-align: center !important;
}

.io-icon-button {
  padding: 0 !important;
}

.disabled-text-field {
  .MuiInput-underline {
    color: rgba(0, 0, 0, 0.87) !important;

    &::before {
      border: none !important;
    }

    &::after {
      border: none !important;
    }
  }
}

.io-modal-wrapper {
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
  min-width: 750px !important;
  max-width: 800px !important;
}

.full-width-chip-label {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
}
