@import "../../../style/variables.less";
@import "../../../style/mixins.less";
@import "../../../style/main.less";

.logs-h2-header {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  .no-data-returned {
    display: inline-block;
    width: 100%;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .log-time {
    flex: 2.2;
    padding-left: 32px;
  }

  .log-type {
    flex: .9;
  }

  .log-session-id {
    flex: 1.3;
  }

  .log-gateway-id {
    flex: 1.3;
  }

  .log-metadata {
    flex: 1.3;
  }

  .log-system-event {
    flex: 2
  }

  .log-message {
    flex: 7.5;
  }

  .log-item-clickable {
    font-size: 14px;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }

  &.log-item {
    max-height: 43px;
    transition: max-height .1s;
    border-top: 1px solid #AAA;
    box-sizing: border-box;
    overflow: hidden;
    padding: 13px 0;

    >div {
      margin: 0 3px;
    }

    &:not(.active)>* {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.active {
      max-height: 600px;
      transition: max-height .5s;
      word-break: break-word;
      word-wrap: break-word;
    }

    &:hover {
      background-color: rgba(80, 80, 80, .05);
    }
  }
}

/* Customize the search field display for the logging tab.
Ensure that it fills whatever space remains between it and the
content in the right side of the header. */
.table-header {

  .table-header-first-line {

    .content-left-side {
      flex-grow: 1; // make the left side content spread to fill the remaining space in the table header
    }

    /* The Search block is the last item contained in the left side of the header */
    .content-left-side>div:last-child {
      flex-grow: 1; // make the search block fill the remaining space in the left side of the table header
      display: flex;
      min-width: 5rem;

      .MuiFormControl-root {
        flex-grow: 1; // make the search input field fill the remaining space after the search icon
      }
    }
  }
}
