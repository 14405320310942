@import "../../../../../../style/variables.less";
@import "../../../../../../style/mixins.less";
@import "../../../../../../style/main.less";

.cds-service-set-activation-ul-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    transition: all 1900ms cubic-bezier(0.25, 0.8, 0.25, 1); // 900ms

    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }

    // .right-icon {
        // opacity: 0;
        // pointer-events: none;

        // button {
        //     top: -10px;
        // }
    // }

    &:hover .right-icon {
        opacity: 1;
        pointer-events: auto;
    }

    .content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .col-1 {
            width: 200px;
            min-width: 200px;
            max-width: 200px;
            padding: 0 4px;
            overflow: hidden;
            text-align: left;
        }

        .col-2 {
            width: 100%;
            min-width: 200px;
            padding: 0 4px;
            overflow: hidden;
            text-align: left;
        }

        .col-3 {
            width: 48px;
            min-width: 48px;
        }
    }
}
