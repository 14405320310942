.new-portal-dialog {
  padding: 0 !important;
  z-index: 10000;

  .new-portal-dialog-title {
    color: #fff !important;
    line-height: 48px !important;
    font-size: 24px !important;
    font-weight: 600 !important;
  }

  .new-portal-dialog-body {
    background: rgb(250, 250, 250);
    padding: 20px 34px 0 34px !important;
  }

  .create-portal-btn {
    margin-top: 34px;
  }

  .portals-loader {
    padding: 24px;
  }

  .new-portal-dialog-content {
    width: 100% !important;
    box-sizing: border-box;
    overflow: hidden;
  }

  .new-portal-close-button {
    position: absolute !important;
    top: 16px;
    right: 24px;

    svg {
      color: rgba(255, 255, 255, 0.85) !important;
    }
  }
}
