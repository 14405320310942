@import "../../../../../../../style/variables.less";
@import "../../../../../../../style/mixins.less";
@import "../../../../../../../style/main.less";

.gw-transactions-tt-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    transition: all 1900ms cubic-bezier(0.25, 0.8, 0.25, 1); // 900ms

    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }

    .content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .time {
            flex: 0.9;
            padding-left: 32px;
            overflow: hidden;
            word-break: break-word;
        }

        .client {
            flex: 0.9;
            overflow: hidden;
            word-break: break-word;
        }

        .user {
            flex: 1;
            overflow: hidden;
            word-break: break-word;
        }

        .resourceType {
            flex: 0.9;
            overflow: hidden;
            word-break: break-word;
        }

        .operationType {
            flex: 0.5;
            overflow: hidden;
            word-break: break-word;
        }

        .latency {
            flex: 0.4;
            overflow: hidden;
            word-break: break-word;
        }

        .queryString {
            flex: 1.5;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    &.active .queryString {
        white-space: normal;
        overflow: visible;
        overflow-wrap: anywhere;
    }
}

.transactionUserTooltip {
    padding: 0 !important;
}
