@import "../../../../../../style/variables.less";
@import "../../../../../../style/mixins.less";
@import "../../../../../../style/main.less";

.activation-ul-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  transition: all 1900ms cubic-bezier(0.25, 0.8, 0.25, 1); // 900ms

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .right-icon {
    opacity: 0;
    pointer-events: none;
  }

  &:hover .right-icon {
    opacity: 1;
    pointer-events: auto;
  }

  .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .col-1 {
      width: 280px;
      min-width: 280px;
      max-width: 280px;
      padding: 0 4px 0 16px;
      overflow: hidden;
      text-align: left;
      cursor: pointer;

      .ehr-auth-type-chip {
        background-color: #4fabe0;
        margin: 4px 8px 4px -4px;
        height: 20px;

        &.disabled {
          background-color: #9e9e9e;

          .chip-label {
            cursor: default;
          }
        }

        .chip-label {
          padding: 2px 8px;
          line-height: 16px;
          color: white;
        }
      }

      .smart-version-chip {
        background-color: #4fabe0;
        margin: 4px 8px 4px -4px;
        height: 20px;

        &.disabled {
          background-color: #9e9e9e;

          .chip-label {
            cursor: default;
          }
        }

        .chip-label {
          padding: 2px 8px;
          line-height: 16px;
          color: white;
        }
      }

      .disable-chip {
        background-color: #9e9e9e;
        margin: 4px 0px 4px -4px;
        height: 20px;

        .chip-label {
          padding: 2px 8px;
          line-height: 16px;
          color: #fff;
          cursor: pointer;
        }
      }
    }

    .col-2 {
      width: 100%;
      min-width: 132px;
      padding: 0 4px;
      overflow: hidden;
      text-align: left;
    }

    .col-3 {
      width: 132px;
      min-width: 132px;
      max-width: 132px;
      padding: 0 4px;
      overflow: hidden;
      text-align: right;
    }
  }
}
