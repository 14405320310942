.container() {
    width: 100%;
    max-width: 1068px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 24px;
    padding-right: 24px;

    &.mid {
        max-width: 1551px;
    }

    &.full {
        max-width: 100%;
    }

    // // @media screen and (max-width: @break-width) {
    // @media screen and (max-width: 1100px) {
    //     // max-width: 100%;
    //     padding-left : 5px;
    //     padding-right: 0px;
    // }
}

.display-flex() {
    display: flex;
    display: -ms-flexbox;
}

.flex-shrink(@val) {
    flex-shrink: @val;
    -ms-flex-negative: @val;
}