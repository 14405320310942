.table-header {
  box-sizing: border-box;
  height: 48px;
  padding: 0 !important;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  .table-header-first-line {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    & > * {
      display: inline-block;
    }

    .content-left-side {
      padding-left: 72px;
      line-height: 48px;
      display: flex;
      align-items: center;

      & > * {
        margin-right: 16px;
      }
    }

    .content-right-side {
      display: flex;
      align-items: center;
      height: 48px;

      & > * {
        margin-right: 16px;
      }

      .header-button {
        top: 5px;
        margin-right: 16px;
      }
    }

    .table-header-search {
      height: 46px;
      border-radius: 2px;
    }
  }
}

.table-header-two {
  display: flex !important;
  padding: 0 !important;
  height: 32px !important;
  justify-content: space-around;

  & > *:first-child {
    padding-left: 32px;
  }
}
