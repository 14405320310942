@import "../../../../../../../../style/variables.less";
@import "../../../../../../../../style/mixins.less";
@import "../../../../../../../../style/main.less";

.gw-mapping-rl-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    transition: all 1900ms cubic-bezier(0.25, 0.8, 0.25, 1); // 900ms

    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }

    .left-icon {
        opacity: 0;
    }

    &:hover .left-icon {
        opacity: 1;
    }

    .right-icon {
        opacity: 0;
        pointer-events: none;
    }

    &:hover .right-icon {
        opacity: 1;
        pointer-events: auto;
    }

    &.is-checked {
        background-color: @color-blue-light;

        .left-icon {
            opacity: 1;
        }

        .right-icon {
            opacity: 0;
            pointer-events: none;
        }
    }

    .any-checked {
        &.right-icon {
            opacity: 0;
            pointer-events: none;
        }
    }

    .content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .type {
            width: 240px;
            min-width: 240px;
            max-width: 240px;
            overflow: hidden;
        }

        .operations {
            width: 100%;
            min-width: 180px;
            overflow: hidden;
            text-align: center;

            .operation-icon {
                margin-right: 8px;
            }
        }

        .source {
            width: 450px;
            min-width: 450px;
            max-width: 450px;
            overflow: hidden;
            text-align: center;

            .chip {
                display: inline-block !important;
                max-width: 450px !important;
                overflow: hidden;
            }
        }
    }
}
