@import "../../../../../../../../style/variables.less";
@import "../../../../../../../../style/mixins.less";
@import "../../../../../../../../style/main.less";

.gw-mapping-rl-h1-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 15px;
    transition: all 900ms cubic-bezier(0.25, 0.8, 0.25, 1);

    .content-left-side {
        margin-top: -4px;

        .icon-button {
            width: auto !important;
            height: auto !important;
            padding: 0 8px !important;
            vertical-align: middle;
        }
    }

    .content-right-side {
        display: flex;
        flex-direction: row;

        .header-dropdown {
            padding-right: 14px;
        }
    }
}
