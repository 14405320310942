.container-legal {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    background: #fff;
    overflow-y: auto;
}

.legal-content {
    a {
        font-size: 12px !important;
    }

    p {
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 30px;
    }

    h2 {
        font-size: 16px;
        font-weight: 700;
        margin: 0 0 27px;
    }

    h1 {
        font-size: 18px;
        font-weight: 500;
    }

    h3 {
        font-size: 16px;
        font-weight: 700;
        color: #757575;
    }

    h5 {
        font-size: 12px;
        font-weight: 700;
        color: #757575;
    }

    ol {
        padding-left: 0;
        list-style: inside upper-roman;

        p {
            margin-bottom: 10px;
            font-weight: 400;
        }

        li {
            margin: 30px 0 0;
            font-size: 18px;
            font-weight: 500;
        }

        ul {
            padding-left: 1rem;
            list-style: outside;
            li {
                font-size: 12px;
                line-height: 20px;
                font-weight: 400;
                margin: 0 0 10px !important;
            }
        }
    }

    ul {
        padding-left: 1rem;
        list-style: outside;

        p {
            margin-left: -1rem;
            margin-bottom: 15px;
            font-weight: 400;
        }

        li {
            font-size: 12px;
            line-height: 20px;
            font-weight: 400;
            margin: 0 0 10px !important;
        }
    }

    .contacting-interopion {
        h5 {
            margin-bottom: 10px;
        }

        p {
            margin-bottom: 10px;
        }

        .contacting-interopion-spacing {
            margin-bottom: 30px;
        }
    }
    .sla-table {
        text-align: center;
    }

    .archive-heading {
        font-size: 24px;
        font-weight: 500;
        margin: 12px 0;
    }

    .archive-content {
        a {
            font-size: 14px !important;
        }
    }
}

