@import "../../../../../../../../style/variables.less";
@import "../../../../../../../../style/mixins.less";
@import "../../../../../../../../style/main.less";

.environment-ul-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    transition: all 1900ms cubic-bezier(0.25, 0.8, 0.25, 1); // 900ms

    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }

    .content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .col-1 {
            padding: 0 4px;
            overflow: hidden;
            text-align: left;
        }

        .col-2 {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 250px;
            padding: 0 4px;
            overflow: hidden;
        }
    }
}
