@import "../../../../../style/variables.less";
@import "../../../../../style/mixins.less";
@import "../../../../../style/main.less";

.app-chart-card {
  margin: 10px 5px !important;
  width: 100%;
  padding-bottom: 20px;
}

.chart-card-text {
  min-height: 200px;
  width: 99%;
}

.loading {
  text-align: center;
  margin-top: 80px;
}

.analytics-toolbar {
  margin: 25px 5px 0;
  width: 100%
}
