.sub-tabs {
    display: flex;
    flex-direction: row;
    height: 44px;
    margin-top: 56px;
    background-color: #00567d;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    transition: all 900ms cubic-bezier(0.25, 0.8, 0.25, 1);

    .tab {
        height: 44px;
        line-height: 44px;
        padding: 0 24px;
        color: rgba(255, 255, 255, 0.67);
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        transition: all 900ms cubic-bezier(0.25, 0.8, 0.25, 1);
    }
    .tab:hover {
        background: rgba(255, 255, 255, 0.15);
    }

    .selected {
        border-bottom: 4px solid #fff;
        color: #fff;
    }
}

.gateway-logging-content {
    .table-wrapper {
        margin-top: 0;
    }
}

.req-res-logging {
    padding: 0 24px 24px 24px;

    .en-dis-req-res-logging {
        width: 732px;
        margin: 24px auto 0 auto;
    }

    .add-new-session {
        display: flex;
        flex-direction: row;
        width: fit-content;
        margin: 24px auto 0 auto;
    }

    .sessions {
        margin: 24px 0 0 0;

        .items-wrapper {
            width: fit-content;
            margin: auto;

            .items {
                width: auto;
                max-width: 1068px;
                margin: auto;

                .item {
                    transition: all 450ms cubic-bezier(0.25, 0.8, 0.25, 1);

                    .id-cell {
                        width: 100px;
                        min-width: 100px;
                        max-width: 100px;
                    }

                    .date-cell {
                        width: 180px;
                        min-width: 180px;
                        max-width: 180px;
                    }

                    .creator-cell {
                        // white-space: nowrap;
                        // overflow: hidden;
                        // text-overflow: ellipsis;
                        // min-width: 100px;
                        // max-width: 200px;
                    }

                    .actions-cell {
                        width: 87px;
                        min-width: 87px;
                        max-width: 87px;
                    }

                    .fade {
                        opacity: 0;
                        transition: all 450ms cubic-bezier(0.25, 0.8, 0.25, 1);
                    }

                    &:hover {
                        background-color: rgba(0, 0, 0, 0.04);

                        .fade {
                            opacity: 1;
                        }
                    }
                }

                .item-head {
                    background-color: rgb(245, 245, 245);
                }
            }
        }
    }
}
