@import "../../../../../../style/variables.less";
@import "../../../../../../style/mixins.less";
@import "../../../../../../style/main.less";

.practice-container {
  margin-top: 20px;
}

.practice-listings {
  margin-top: 16px;
  margin-bottom: 16px;

  .new-practice-listing {
    padding: 10px 20px 20px 20px;
    margin-top: 10px;

    .new-practice-listing-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      h5 {
        font-size: 1rem;
        color: #888;
        margin: 0;
      }
    }
  }

  .table-wrapper {
    margin-top: 8px;

    .table-header-two>*:first-child {
      padding-left: 0;
    }

    li {
      display: flex;
    }

    li:nth-child(even) {
      background: rgb(245, 245, 245);
    }
  }

  .practice-listing-ul-item {
    .content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .col-1 {
        width: calc(50% + 48px);
        max-width: 50%;
        padding: 0 4px;
        overflow: hidden;
        text-align: left;
      }

      .col-2 {
        width: 50%;
        max-width: 50%;
        padding: 0 4px;
        overflow: hidden;
        text-align: left;
      }

    }
  }
}
