@import "../../../../../../../style/variables.less";
@import "../../../../../../../style/mixins.less";
@import "../../../../../../../style/main.less";

.gw-transactions-tt-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    transition: all 1900ms cubic-bezier(0.25, 0.8, 0.25, 1); // 900ms

    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }

    .content-aggregated {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .medianTime {
            flex: 1;
            overflow: hidden;
            word-break: break-word;
        }

        .avgTime {
            flex: 1;
            overflow: hidden;
            word-break: break-word;
        }

        .resourceType {
            flex: 1;
            overflow: hidden;
            word-break: break-word;
            padding-left: 22px;
        }

        .operationType {
            flex: 1;
            overflow: hidden;
            word-break: break-word;
        }

        .count {
            flex: 1;
            overflow: hidden;
            word-break: break-word;
        }
    }
}

.transactionUserTooltip {
    padding: 0 !important;
}
