@import "../../../../../../../style/variables.less";
@import "../../../../../../../style/mixins.less";
@import "../../../../../../../style/main.less";

.app-chart-card {
  margin-top: 10px !important;
}

.analytics-toolbar {
  margin: 25px 5px 0;
  width: 100%
}
