@import "../../../../style/variables.less";
@import "../../../../style/mixins.less";
@import "../../../../style/main.less";

.sorting-icon {
    height: 16px !important;
    width: 16px !important;
    margin-left: 4px;
    vertical-align: bottom;
    opacity: 1;
    cursor: pointer;
    transition: all 900ms cubic-bezier(0.25, 0.8, 0.25, 1);

    &.hidden {
        cursor: default;
        opacity: 0;
    }
}
