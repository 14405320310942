#paginaton-button {
  padding: 10px 8px;
  font-size: 1em;
  height: 23px !important;

  & > div {
    position: relative;
    top: -6px;
  }
}

.pagination-menu-container {
  flex-direction: column;
  display: flex;
  width: 300px;
  padding: 5px;

  .pagination-size {
    display: flex;

    .pagination-menu-page-text {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .pagination-select-additional {
        button {
          padding-right: 0 !important;
        }

        .MuiSvgIcon-root.MuiSelect-icon {
          width: 22px;
        }

        .MuiSelect-select.MuiSelect-select {
          margin-left: 3px;
          margin-right: 3px;
        }
      }
    }

    .pagination-menu-page-size-selector {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      & > div {
        width: auto !important;
      }

      label {
        white-space: nowrap;
      }
    }

  }

  .pagination-menu-page-selector-container {
    margin: 0 auto;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;

    .pagination-dots {
      display: inline-block;
      text-align: center;
      position: relative;
      top: 5px;
    }

    .pagination-select-button {
      font-size: .7em;
      padding: 5px;
      width: 28px;
      flex-grow: 1;
      flex-basis: 1% !important;
      min-width: 30px !important;
      margin: 0 4px;
    }
  }
}
