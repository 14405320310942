@import "../../../../../../../style/variables.less";
@import "../../../../../../../style/mixins.less";
@import "../../../../../../../style/main.less";

.gw-subscriptions-tt-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    transition: all 1900ms cubic-bezier(0.25, 0.8, 0.25, 1); // 900ms

    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }

    .content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .end {
            flex: 1.11;
            overflow: hidden;
            word-break: break-word;
        }

        .status {
            flex: .92;
            overflow: hidden;
            word-break: break-word;
        }

        .reason {
            flex: 1.11;
            overflow: hidden;
            word-break: break-word;
        }

        .criteria {
            flex: 2.45;
            overflow: hidden;
            word-break: break-word;
        }

        .contact {
            flex: 1.11;
            overflow: hidden;
            word-break: break-word;
        }

        .channelType {
            flex: 1.11;
            overflow: hidden;
            word-break: break-word;
        }
    }
}
