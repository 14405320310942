@import "../../../../../style/variables.less";
@import "../../../../../style/mixins.less";

.app-chart-card {
  margin: 10px 5px !important;
  width: 100%;
  padding-bottom: 20px;
}

.transactions-toolbar {
  margin: 25px 5px 0;
  width: 100%
}

.da-transactions-container {
  margin-top: 48px;
}
