@import "../../../../../../style/variables.less";
@import "../../../../../../style/mixins.less";
@import "../../../../../../style/main.less";

.acc-ul-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    transition: all 1900ms cubic-bezier(0.25, 0.8, 0.25, 1); // 900ms

    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }

    .right-icon {
        opacity: 0;
        pointer-events: none;
    }

    &:hover .right-icon {
        opacity: 1;
        pointer-events: auto;
    }

    .content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .user {
            width: 240px;
            min-width: 240px;
            max-width: 240px;
            overflow: hidden;
            text-align: left;
        }

        .role {
            width: 100%;
            min-width: 120px;
            overflow: hidden;
            text-align: left;
        }

        .status {
            width: 100%;
            min-width: 120px;
            overflow: hidden;
            text-align: left;
        }

        .type {
            width: 100%;
            min-width: 120px;
            overflow: hidden;
            text-align: left;
        }
    }
}
