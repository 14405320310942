@import "../../style/variables.less";
@import "../../style/mixins.less";
@import "../../style/main.less";

.left-menu-private {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: #262f3d;
    z-index: 1200;
    transition: all 900ms cubic-bezier(0.25, 0.8, 0.25, 1);

    a {
        text-decoration: none;
    }

    &.lmp-expanded {
        width: 256px;
    }

    &.lmp-shrunk {
        width: 64px;
    }

    .border-bottom {
        border-bottom: 1px solid #404854;
    }

    .border-top {
        border-top: 1px solid #404854;
    }

    .logo {
        display: flex;
        flex-direction: row;
        flex-shrink: 0;
        align-items: center;
        height: 56px;
        margin: 0;
        padding: 0;
        // white-space: nowrap;
        overflow: hidden;
        // cursor: pointer;

        .logo-img {
            width: auto;
            height: 32px;
            margin-left: 20px;
            transition: all 900ms cubic-bezier(0.25, 0.8, 0.25, 1);
        }

        .logo-icon {
            margin-left: 16px;
        }
    }

    .items {
        display: flex;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
        flex-direction: column;
        margin: 0;
        padding: 0;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .item {
        height: 40px;
        min-height: 40px;
        margin: 0;
        padding: 8px 0 8px 20px;
        color: rgba(255, 255, 255, 0.9);
        font-size: 13px;
        font-weight: 500;
        text-align: left;
        letter-spacing: 0.1rem;
        line-height: 24px;
        vertical-align: middle;
        white-space: nowrap;
        overflow: hidden;
        // cursor: pointer;
        transition: all 900ms cubic-bezier(0.25, 0.8, 0.25, 1);

        span {
            vertical-align: middle;
        }

        &:hover {
            background-color: rgba(255, 255, 255, 0.1);
        }

        .icon-item {
            width: 24px !important;
            height: 24px !important;
            margin-right: 24px;
            vertical-align: middle;
        }
    }

    .sub-item {
        height: 40px;
        min-height: 40px;
        padding: 8px 0 8px 24px;
        background-color: #19212b;
        color: rgba(255, 255, 255, 0.7);
        // font-size: 14px;
        font-weight: 400;
        letter-spacing: normal;
        line-height: 24px;

        &:hover {
            background-color: rgba(255, 255, 255, 0.05);
        }

        .icon-item {
            width: 20px !important;
            height: 20px !important;
        }
    }

    // .item-shrunk {
    //     padding-left: 22px;
    // }

    // .item-visible {}

    .item-hidden {
        min-height: 0;
        height: 0;
        max-height: 0;
        padding: 0 0 0 24px;
        border: none;
    }

    .item-selected {
        color: @color-orange-dark;
    }

    .item-not-selected {
        color: rgba(255, 255, 255, 0.9);
    }

    .item-disabled {
        cursor: default;

        // &:hover {
        //     background-color: transparent;
        // }
    }

    .btn-toggle {
        padding: 5px 22px;
        text-align: right;
        line-height: 48px;
        vertical-align: middle;
        // cursor: pointer;

        svg {
            color: rgba(255, 255, 255, 0.6) !important;
            cursor: pointer;
        }

        svg:hover {
            color: rgba(255, 255, 255, 0.9) !important;
        }

        &:hover {
            background-color: transparent;
        }
    }

    .item-with-right-icon {
        display: flex;
        justify-content: space-between;

        .item-left {
            width: 211px;
        }

        .item-right-icon {
            height: 40px;
            min-height: 40px;
            padding: 8px 0;

            &:hover {
                background-color: rgba(255, 255, 255, 0.1);
            }

            .icon-right-svg {
                padding: 0 10px;
                border-left: 1px solid #404854;
            }
        }
    }

    .item-with-right-icon-column {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        .item-right-icon {
            height: 40px;
            min-height: 40px;
            padding: 8px 0 8px 20px;

            &:hover {
                background-color: rgba(255, 255, 255, 0.1);
            }
        }
    }


}
