.login-item {
  display: flex;
  flex-direction: row;
  line-height: 45px;

  &:hover {
    background-color: whitesmoke;
    cursor: pointer;
  }

  & > * {
    flex: 1;

    &:first-child {
      padding-left: 32px;
    }
  }
}
