@import "../../../../../../style/variables.less";
@import "../../../../../../style/mixins.less";
@import "../../../../../../style/main.less";

.acc-ul-h1-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    line-height: 24px;
    transition: all 900ms cubic-bezier(0.25, 0.8, 0.25, 1);
    opacity: .5;
    position: relative;
    top: 4px;

    .content-right-side {
        display: flex;
        flex-direction: row;
    }
}
