@import "../../style/variables.less";
@import "../../style/mixins.less";
@import "../../style/main.less";

.app-footer {
    .display-flex();
    .flex-shrink(0);

    // width: 100%;
    background-color: @color-blue-dark;
    border-color: @color-blue-dark;
    // position: absolute;
    bottom: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    // border-top: 2.5px solid;
    text-align: left;
    color: white;
    font-size: 80%;

    a {
        color: white !important;
        font-weight: 600;
        text-decoration: none;
    }
    .footer-container {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        text-align: center;
    }
}