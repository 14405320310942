.table-header-cell {
  display: flex;
  align-items: center;
  user-select: none;
  font-size: 13px;

  &.active {
    cursor: pointer;
  }

  &.centered {
    justify-content: center;
  }
}
