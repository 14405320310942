.portal-icon-copy {
  width: 26px !important;
  height: 26px !important;
  position: absolute;
  top: 8px;
  opacity: .7;
}

.portal-logo-card {
  &:hover {
    .logo-edit-button {
      display: block !important;
    }
  }

  .logo-edit-button {
    position: absolute !important;
    right: 0 !important;
    top: 5px !important;
    margin-right: 0 !important;
    display: none !important;
  }
}

.portal-gateway-link-title {
  margin-top: 24px;
  position: relative;
  line-height: 22px;
  top: 30px;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  z-index: 1;
  transform: scale(0.75) translate(0px, -28px);
  transform-origin: left top;
  pointer-events: none;
  user-select: none;
  color: rgba(0, 0, 0, 0.3);
}

.portal-type {
  margin-top: 24px;
  
  .portal-type-descr {
    color: rgba(0, 0, 0, 0.4);
  }
}
