@import "../../../../style/variables.less";
@import "../../../../style/mixins.less";
@import "../../../../style/main.less";

.environment-dashboard {
  width: 100%;
  // height: 100%;
  padding: 0;
  background: linear-gradient(to bottom, @color-blue-dark 0%, @color-blue-dark 360px, rgba(0, 0, 0, 0) 360px, rgba(0, 0, 0, 0) 100%);

  .titleSmall {
    margin: 0;
    color: #fff;
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
  }

  .titleLarge {
    margin: 0;
    color: #fff;
    font-size: 56px;
    font-weight: 500;
    line-height: 72px;
  }

  .sub {
    margin: 48px 0 24px 0;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
  }
}
