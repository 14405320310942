@import "../../../../style/variables.less";
@import "../../../../style/mixins.less";
@import "../../../../style/main.less";

.user-dirs {
    width: 100%;
    // height: 100%;
    padding: 0;
    background: linear-gradient(to bottom, #fff 0%, #fff 360px, rgba(0, 0, 0, 0) 360px, rgba(0, 0, 0, 0) 100%);
}
