.app-edit-card {
  margin-top: 48px;

  .right-edit-button {
    margin: 12px !important;
  }

  .input-margin-bottom {
    margin-bottom: 16px;
  }
}
