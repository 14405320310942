@import "../../../../../../../../style/variables.less";
@import "../../../../../../../../style/mixins.less";
@import "../../../../../../../../style/main.less";

.user-environment-permissions-h2-content {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: auto;
    padding: 0;
    transition: all 900ms cubic-bezier(0.25, 0.8, 0.25, 1);

    .col-1 {
        padding: 0 4px;
        overflow: hidden;
        text-align: left;
    }

    .col-2 {
        width: 250px;
        padding: 0 4px;
        overflow: hidden;
        text-align: center;
    }
}
