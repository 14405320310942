.operation-table-row {
  display: flex;
  justify-content: space-around;
  height: 51px;
  line-height: 51px;
  border-bottom: 1px solid rgb(224, 224, 224);

  &:hover {
    background: rgba(245, 245, 245, 1);
  }

  & > * {
    flex: 1;

    &:first-child {
      flex: 2;
      padding-left: 32px;
    }
  }
}
