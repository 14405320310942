.custom-contexts {
    margin-top: 16px;
    margin-bottom: 16px;

    .MuiInputLabel-shrink {
        transform: translate(0, 8px) scale(0.75);
        z-index: 1;
    }

    .MuiFab-sizeSmall {
        width: 35px;
        height: 35px;
    }

    .edit-custom-context {
        width: 100%;
    }

    .new-custom-context {
        padding: 10px 20px 20px 20px;
        margin-top: 10px;

        .new-custom-context-header {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          margin-bottom: -13px; /* pull the input immediately following this header up closer to it to reduce the gap */

          h5 {
            font-size: 1rem;
            color: #888;
            margin: 0;
          }
        }
    }

    li {
      display: flex;
    }

    li:nth-child(even) {
      background: rgb(245, 245, 245);
    }

    .table-wrapper {
      margin-top: 8px;

      .table-header-two>*:first-child {
        padding-left: 20px;
      }

      .table-header-two>.table-header-cell {
      color: rgba(0, 0, 0, 0.5)!important;
      }

      .custom-context-list-item {
        .content {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          > div {
             overflow: hidden;
             text-overflow: ellipsis;
          }

          > div:hover {
              overflow: visible;
              overflow-wrap: break-word;
              display: block;
          }

          .col-1 {
            width: 48%;
            max-width:48%;
            padding: 0 4px;
            text-align: left;
          }

          .col-2 {
            width: 48%;
            max-width: 48%;
            padding: 0 4px;
            text-align: left;
          }

        }
      }
    }
}