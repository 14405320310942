@import "../../../../style/variables";

.data-adapters {
  .data-adapter {
    .adapter-content {
      padding-left: 10px;
      padding-bottom: 5px;

      .status {
        display: inline-block;
        width: 50px;
        vertical-align: middle;

        svg {
          width: 30px !important;
          height: 30px !important;
          position: relative;
          top: 3px;
        }

        &.hidden {
          opacity: 0;
        }

        &.healthy {
          svg {
            fill: @color-green !important;
          }
        }

        &.unhealthy {
          svg {
            fill: @color-red-dark !important;
            transform: rotate(45deg);
          }
        }
      }

      .totals-sub {
        text-align: center;
        width: 130px;
        display: inline-block;
        vertical-align: middle;

        .postfix {
          font-size: 12px;
        }

        hr {
          width: 20% !important;
        }

        .percent {
          margin: 20px 0 auto;
          width: 100%;
          font-size: 18px;
          color: @color-blue-dark;
        }
      }

      .section-title {
        display: inline-block;
        vertical-align: middle;
        width: ~'calc(100% - 640px)';
      }
    }
  }
}
