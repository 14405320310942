@import "../../../style/variables.less";
@import "../../../style/mixins.less";
@import "../../../style/main.less";

.IO-chip-popover-menu {
    padding: 0 !important;

    .title {
        display: flex;
        width: 100%;
        margin: 0;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .text {
        flex-grow: 1;
        padding: 5px;
        font-size: 26px;
    }
    .icon {
        margin: 3px;
        cursor: pointer;
    }
}

.IO-chip-popover {
    div[role=presentation] {
        width: auto !important;
    }
}
