@import "../../style/variables.less";
@import "../../style/mixins.less";
@import "../../style/main.less";

.app-root {
    display: flex;
    flex-direction: column;

    height: 100%;

    .stage {
        display: flex;
        flex-grow: 1;
        flex-shrink: 1;
        // flex-basis: 100px;
        flex-basis: auto;
        align-items: stretch;
        align-self: stretch;
        flex-direction: column;
        justify-content: space-between;

        // overflow: auto;
        // overflow: overlay;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        min-width: 310px;
        background: @color-bgnd-grey-light;
        transition: all 900ms cubic-bezier(0.25, 0.8, 0.25, 1);
    }

    .stage-bgnd-dark {
        background: @color-bgnd-grey-dark;
    }
}
