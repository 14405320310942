@import "../../../../../../style/variables.less";
@import "../../../../../../style/mixins.less";
@import "../../../../../../style/main.less";

.acc-ul-h2-content {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 56px 0 56px;
    transition: all 900ms cubic-bezier(0.25, 0.8, 0.25, 1);

    .user {
        width: 240px;
        min-width: 240px;
        max-width: 240px;
        overflow: hidden;
        text-align: left;
    }

    .role {
        width: 100%;
        min-width: 120px;
        overflow: hidden;
        text-align: left;
    }

    .status {
        width: 100%;
        min-width: 120px;
        overflow: hidden;
        text-align: left;
    }

    .type {
        width: 100%;
        min-width: 120px;
        overflow: hidden;
        text-align: left;
    }
}
