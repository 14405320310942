@import "../../../style/variables.less";
@import "../../../style/mixins.less";
@import "../../../style/main.less";

.custom-link-component {
    text-decoration: none;

    &:hover {
        font-weight: 800;
        cursor: pointer;
    }
}
