.app-monitoring-wrapper {
  border: 3px solid lightgray;
  padding: 16px;
  margin: 10px 0;
  border-radius: 3px;
  background-color: #FEFEFE;

  .first-line-app-monitoring {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;

    & > *:first-child {
      flex: 2;
    }

    & > *:not(:first-child) {
      flex: 1;
    }

    .monitoring-duration {
      display: inline-block;
      text-align: right;
      padding-right: 40px;
      position: relative;

      .export-button {
        position: absolute !important;
        width: 30px !important;
        height: 30px !important;
        padding: 0 !important;
        margin: 0 !important;
        top: -5px;
        right: 0;

        & > * {
          width: 30px !important;
          height: 30px !important;
          padding: 0 !important;
          margin: 0 !important;
        }
      }
    }
  }

  .app-status-history {
    border: 3px solid lightgray;
    padding: 16px;
    border-radius: 3px;
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: whitesmoke;

    .line, .dot {
      line-height: 20px !important;
    }

    .line {
      display: inline-block !important;
      width: 3px !important;
      height: 25px !important;
      background-color: red !important;
      padding: 0 !important;
    }

    .dot {
      display: inline-block;
      width: 10px;
      height: 10px;
      background-color: green;
      border-radius: 10px;
    }

    .no-app-data-available {
      text-align: center;
      display: inline-block;
      width: 100%;
      color: darkgray;
    }
  }
}
