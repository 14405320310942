@import "../../../../../../../../style/variables.less";
@import "../../../../../../../../style/mixins.less";
@import "../../../../../../../../style/main.less";

.user-environment-permissions-h1-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    line-height: 24px;
    transition: all 900ms cubic-bezier(0.25, 0.8, 0.25, 1);

    .content-left-side {
        margin-top: -16px;
    }

    .content-right-side {
        display: flex;
        flex-direction: row;
    }
}
