@import "../../style/variables.less";
@import "../../style/mixins.less";
@import "../../style/main.less";

.navbar-l1-private {
    flex-direction: row !important;
    justify-content: space-between;
    height: 56px;
    padding-right: 24px;
    transition: all 900ms cubic-bezier(0.25, 0.8, 0.25, 1) !important;

    .title-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 56px;
        line-height: 56px;
        font-size: 18px;
        color: #fff;

        .title-l1 {
            display: flex;
            flex-direction: row;
            align-items: center;
            line-height: 56px;
            height: 56px;
            padding-left: 24px;
        }

        .title-l1-empty {
            padding-left: 0;
        }

        .title-l2 {
            padding-left: 24px;
            font-weight: 600;
            opacity: 1;
            transition: all 900ms cubic-bezier(0.25, 0.8, 0.25, 1);
        }

        .title-l2-hidden {
            opacity: 0;
        }
    }
}

.navbar-l2 {
    flex-direction: row !important;
    align-items: center;
    justify-content: space-between;
    height: 56px;
    padding: 0 24px;
    box-shadow: none !important;
    transition: all 900ms cubic-bezier(0.25, 0.8, 0.25, 1) !important;
}

.navbar-l3 {
    flex-direction: row !important;
    height: 44px;
    padding-left: 0 !important;
    line-height: normal;
    box-shadow: none !important;
    transition: all 900ms cubic-bezier(0.25, 0.8, 0.25, 1) !important;

    .tab {
        width: fit-content;
        height: 44px;
        line-height: 44px;
        padding: 0 24px;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
        border-bottom: none;
        color: rgba(255, 255, 255, 0.67);
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;
        transition: all 900ms cubic-bezier(0.25, 0.8, 0.25, 1);

        svg {
            width: 20px !important;
            height: 20px !important;
            padding-right: 4px;
            padding-bottom: 2px;
            color: rgba(255, 255, 255, 0.67) !important;
            vertical-align: middle;
        }
    }
    .tab:hover {
        background: rgba(255, 255, 255, 0.15);
    }
    .tab:active {
        background: rgba(255, 255, 255, 0.5);
    }

    .tab-selected {
        border-bottom: 4px solid #fff;
        color: #fff;

        svg {
            color: #fff !important;
        }
    }

    .tab-disabled {
        color: rgba(255, 255, 255, 0.33);
        cursor: not-allowed;
    }

    .tab-error {
        color: @color-red-dark;

        svg {
            color: @color-red-dark !important;
        }
    }
}
