@import "../../../../style/variables.less";
@import "../../../../style/mixins.less";
@import "../../../../style/main.less";

.portals {
  width: 100%;
  // height: 100%;
  padding: 0;
  background: linear-gradient(to bottom, #fff 0%, #fff 360px, rgba(0, 0, 0, 0) 360px, rgba(0, 0, 0, 0) 100%);

  .portals-page-title {
    color: #fff;
    font-size: 24px;
    text-decoration: none
  }

  .container {
    padding-top: 48px;

    .portal-card-wrapper {
      width: 320px;
      height: 244px;
      min-height: 200px;
      align-content: inherit;
      padding-top: 8px;

      .bar-title {
        font-weight: bold;
      }

      // .footer-link {
      //   text-decoration: none;
      //   font-weight: bold;
      // }

      .card-footer {
        padding-top: 0;
      }

      .portal-pill-icon {
        position: relative;
        top: 5px;
        left: 6px;
        color: #fff;
      }

      &.portal-add-new-card {
        & > div {
          height: 100%;
        }

        .body-icon {
          padding-top: 35px;
        }
      }
    }

    .title-line1 {
      margin: 0 0 15px 0;
      font-size: 24px;
      font-weight: 400;
      line-height: 28px;
    }

    .title-line2 {
      width: 350px;
      margin: 0 0 15px 0;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
    }

    .title-link {
      margin: 0 0 60px 0;

      a {
        margin: 0 15px 0 0;
      }
    }
  }
}
