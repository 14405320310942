.account-create-request {
    width: 100%;
    display: flex;
    justify-content: center;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .account-id-wrapper {
        display: block;
        position: relative;

        .text-field {
            position: relative;
        }

        .suffix {
            position: absolute;
            right: 0;
            bottom: 12px;
            font-weight: 600;

            &.error {
                bottom: 40px;
            }
        }
    }
}
