@import "../../../style/variables.less";
@import "../../../style/mixins.less";
@import "../../../style/main.less";

.cards-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.card-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-between;
  width: 296px;
  min-width: 296px;
  min-height: 296px;
  margin: 0 20px 20px 0;
  background-color: #fff;
  // border-radius: 2px;
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
  0 1px 1px 0 rgba(0, 0, 0, 0.14),
  0 2px 1px -1px rgba(0, 0, 0, 0.12);
  transition: all 900ms cubic-bezier(0.25, 0.8, 0.25, 1);

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2),
    0 3px 1px 0 rgba(0, 0, 0, 0.14),
    0 6px 1px -1px rgba(0, 0, 0, 0.12);
  }

  .card-image {
    display: block;
    width: 100%;
    cursor: pointer;

    img {
      width: 100%;
      height: auto;
      border-radius: 6px 6px 0 0;
    }
  }

  .card-menu-image {
    display: block;
    width: 100%;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: 308px 145px;

    .image-menu {
      text-align: right;
      text-align: end;
    }
  }

  .card-menu-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 16px;

    .bar-title {
      display: block;
      font-size: 20px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.87);
      cursor: pointer;

      &.single-line {
        font-size: 18px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .bar-menu {
      > * > button {
        padding: 0 !important;
        width: 24px !important;
        height: 24px !important;
      }
    }
  }

  .card-body {
    display: block;
    width: 100%;
    min-height: 120px;
    padding: 16px;
    cursor: pointer;

    .body-title {
      display: block;
      margin-bottom: 8px;
      font-size: 20px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.87);
    }

    .body-text {
      display: block;
      margin-bottom: 8px;
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.54);
    }
  }

  .card-body-add-new {
    display: block;
    width: 100%;
    min-height: 120px;
    padding: 65px 48px;
    cursor: pointer;

    .body-icon {
      margin: 0;
      text-align: center;

      svg {
        width: 48px !important;
        height: 48px !important;
        fill: @color-blue-dark !important;
      }
    }

    .body-text {
      margin: 0;
      font-size: 13px;
      font-weight: 600;
      text-align: center;
      color: @color-blue-dark;
      text-transform: uppercase;
    }
  }

  .card-custom-content {
    display: block;
    width: 100%;
    // min-height: ...px;
    padding: 16px;
    cursor: pointer;
  }

  .card-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    min-height: 44px;
    padding: 4px 4px 4px 16px;

    .footer-link {
      max-width: 100%;
      padding: 8px 0;
      line-height: 20px;
      word-wrap: break-word;

      &.disabled {
        color: #9b9fa5;
      }
    }
  }

  .card-footer-add-new {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    min-height: 44px;
    padding: 4px 4px 4px 16px;
    border-top: 1px solid rgba(0, 0, 0, 0.14);

    .footer-link {
      width: 100%;
      max-width: 100%;
      padding: 8px 0;
      line-height: 20px;
      word-wrap: break-word;
      text-align: center;
      font-weight: 600;
      text-decoration: none;

      &.disabled {
        color: #9b9fa5;
      }
    }
  }
}

.card-image-menu-popover {
  border-radius: 8px !important;
  padding: 5px 0;
  width: 100px;
}
